
import '../styles/landing-page.css';
import { Link } from 'react-router-dom';
import WelcomeBackground from '../components/welcome-background';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareFacebook, faLinkedin, faSquareInstagram } from '@fortawesome/free-brands-svg-icons'; // <-- import styles to be used
import { Amplify, Storage } from "aws-amplify";
import { S3Image } from 'aws-amplify-react-native';
import awsmobile from '../aws-exports';

function LandingPage () {
    
    Amplify.configure(awsmobile);
    return(
    <div>    
        <div className="s3"><S3Image imgKey="welcome/chase-file-change.png" resizeMode="contain" style={{height: "100%", width: "100%"}}/></div>
        <div className='icons'><p></p>
            <FontAwesomeIcon icon={faSquareFacebook} size="xl" /> &nbsp; 
            <FontAwesomeIcon icon={faLinkedin} size="xl"/> &nbsp; 
            <FontAwesomeIcon icon={faSquareInstagram} size="xl"/> &nbsp; 
        </div>
        <WelcomeBackground></WelcomeBackground>
    </div>
    );

}
export default LandingPage;