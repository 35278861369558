import React, {useState, useEffect} from 'react';
import CrossfadeImage from "react-crossfade-image";


function WelcomeBackground () {    


    const [curImg, setCurImg] = useState(0); 

    const images = [
        "https://scontent-lga3-1.xx.fbcdn.net/v/t1.15752-9/324654965_887794038926131_3306692486725782670_n.jpg?stp=dst-jpg_s2048x2048&_nc_cat=102&ccb=1-7&_nc_sid=ae9488&_nc_ohc=NKPJRKlCRlsAX_D-vUu&_nc_ht=scontent-lga3-1.xx&oh=03_AdQ9J0hoCUubrBZGx8KSw0LOFV5l4BFGo0AJnmC5nMQx5Q&oe=63FC152A",
        "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
        "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80"
    ];
      
    useEffect(() => {

        let interval;
    
        interval = setInterval(() => {
          if (curImg < 2) {
            setCurImg(img => img + 1);
          } else {
            setCurImg(0);
          }
        }, 3000);
    
        return () => clearInterval(interval);
      }, [curImg]);


    return (
        <div className="fill-window">
      <CrossfadeImage
        src={images[curImg]}
        duration={1000}
        delay={200}
        timingFunction={"ease-in-out"}
        style={{width: "100%",
        height: "100%",
        float: "left"}}
        containerClass="fill-window"/>
        </div>
    );
}

export default WelcomeBackground