function AboutPage () {
    

    return(
    <div>    
        Collections here!
    </div>
    
    );

}

export default AboutPage;