import React from 'react';
import './styles/App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from './pages/landing-page';
import GalleryPage from './pages/gallery-page';
import AboutPage from './pages/about-page';
import Navigation from './components/navigation';
import pageList from './assets/pageList';
import Layout from './pages/layout';

function App() {

  return (
    <div className="fill-window">
      <Router>
          <Routes>
            <Route path='/' element={<LandingPage/>}></Route>
            <Route element={<Layout/>}>
              {pageList.map((page) => (<Route path={'/' + page} element={<GalleryPage key={pageList.indexOf(page)} collectionKey={page}/>}></Route>))}
              <Route path='/about' element={<AboutPage/>}></Route>
            </Route>
          </Routes>
      </Router>
    </div>
  );
}

export default App;
