import { ImageList, ImageListItem } from "@mui/material";
import { Amplify } from "aws-amplify";
import { S3Image } from 'aws-amplify-react-native';
import awsmobile from "../aws-exports";

interface IPhotogridProps {
    keyList: string[];
}

function Photogrid(props: IPhotogridProps) {
    Amplify.configure(awsmobile);
    return (
            <ImageList sx={{ width: "100vh", height: "100vh" }} variant="masonry" cols={3} gap={8}>
            {props.keyList.map((imageKey) => (
                <ImageListItem key={imageKey}>
                    <S3Image imgKey={imageKey}  style={{height: '200px'}} resizeMode="center"/>
                </ImageListItem>
                ))
            }
            </ImageList>
    );
}
export default Photogrid;