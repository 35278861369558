import '../styles/gallery-page.css'
import React, { useState } from "react";
import { Amplify, Storage } from "aws-amplify";
import awsmobile from ".././aws-exports";
import { S3Album, S3Image } from 'aws-amplify-react-native';
import Photogrid from '../components/photogrid';

Amplify.configure(awsmobile);

interface GalleryProps {
  collectionKey: string;
}

function GalleryPage (props: GalleryProps) {
  
  const [keyArray, setKeyArrayState] = useState<string[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  
  if (!loaded) {
    Storage.list(props.collectionKey)
      .then((result) => {
        var resList = result.results.filter((item) => item.size != undefined && item.size > 0).map((item) => item.key!);
        setKeyArrayState(resList);
        setLoaded(true);
        console.log(props.collectionKey)
      })
      .catch(err => {
      console.log(err);
    })
  }
  return(
      <div className="gallery">
          <Photogrid keyList={keyArray}></Photogrid>
      </div>
  );

}
export default GalleryPage;