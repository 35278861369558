import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Slide, Fade } from '@mui/material';
import '../styles/navigation.css';
import { Amplify, Storage } from "aws-amplify";
import { S3Image } from 'aws-amplify-react-native';
import awsmobile from '../aws-exports';

    
Amplify.configure(awsmobile);

function Navigation () {

    return(
            <div className='nav'>
                <div className="s3"><S3Image imgKey="welcome/chase-file-change.png" resizeMode="contain" style={{height: "100%", width: "100%", position: "relative", left: "1.5vw", align: "left"}}/></div>
                    <div className="fill-window" style={{backgroundColor:"#ffffff"}}>
                        <h2>poo</h2>
                    </div>
            </div>
    );

}

export default Navigation;